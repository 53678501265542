.App-link {
  color: #61dafb;
}

.primary {
  background-color: #E6EFEF;
}

.monospace {
  font-family: monospace;
}